var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item.submenu)?_c('div',{staticClass:"group",attrs:{"id":_vm.item.name}},[_c('li',{staticClass:"mb-3 px-4 py-4 grid text-black rounded rounded-lg text-center justify-center cursor-pointer",class:[
                {'bg-template5-warning text-template5-primary hover:text-white': _vm.checkGrpChildrenActive(_vm.item)},
                {'bg-gray-100 text-template5-primary hover:text-template5-primary': !_vm.checkGrpChildrenActive(_vm.item)} ]},[(_vm.item.svg)?_c('span',{staticClass:"mx-auto pb-2"},[_c('inline-svg',{attrs:{"src":require(("@/assets/svgs/template3/navbar/" + (_vm.item.svg)))}})],1):_vm._e(),_c('p',{staticClass:"font-bold menu-title-hover"},[_c('span',[_vm._v(_vm._s(_vm.$t(_vm.item.i18n) || _vm.item.name))])])]),_c('div',{staticClass:"mb-2 bg-gray-800 px-1 py-1 rounded-lg",class:[
      {'block menu-title-hover': _vm.checkGrpChildrenActive(_vm.item)},
      {'hidden group-hover:block': !_vm.checkGrpChildrenActive(_vm.item)}
    ],attrs:{"id":_vm.item.name + '-submenu'}},_vm._l((_vm.item.submenu),function(subItem,index){return _c('div',{},[_c('router-link',{attrs:{"to":subItem.url}},[_c('li',{staticClass:"mx-1 my-2 px-4 py-4 grid text-black rounded rounded-lg text-center justify-center cursor-pointer",class:[
                      {'bg-template5-warning text-white hover:text-white': _vm.activeLink(subItem)},
                      {'bg-gray-100 text-gray-600 hover:text-template5-primary': !_vm.activeLink(subItem)} ]},[(subItem.svg)?_c('span',{staticClass:"mx-auto pb-2"},[_c('inline-svg',{attrs:{"src":require(("@/assets/svgs/template3/navbar/" + (subItem.svg)))}})],1):_vm._e(),_c('p',{staticClass:"font-bold"},[_c('span',[_vm._v(_vm._s(_vm.$t(subItem.i18n) || subItem.name))])])])])],1)}),0)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }