<template>
  <div class="flex justify-end my-2 az-pagination">
    <div class="flex flex-row h-8">
      <div
        v-if="showClose && current && current !== ''"
        @click="clearSelection"
        class="w-6 h-6 rounded-full bg-temp3-danger text-white mx-1 align-middle my-auto">
        <feather-icon icon="XIcon" svg-classes="h-6 w-6 p-1"/>
      </div>
      <div class="flex flex-row font-medium rounded-full bg-gray-200">
        <div
          v-for="(a, index) in listAlpha"
          @click="selectAlpha(a)"
          class="w-8 flex justify-center items-center cursor-pointer leading-5 transition duration-150 ease-inrounded-full hover:text-primary"
          :class="[
          {'bg-temp3-primary text-white rounded-full': current.toUpperCase() === a}
        ]">
          {{ a }}
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "AZPagination",
  data() {
    return {
      listAlpha: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z"
      ],
      current: '',
    };
  },
  props: {
    showClose: {
      default: true,
      type: Boolean,
    },
    selectedAlpha: {
      default: '',
      type: String,
    }
  },
  methods: {
    selectAlpha(a) {
      this.current = a;
      this.$emit('change', a)
    },
    clearSelection() {
      this.current = '';
      this.$emit('change', '');
      this.$emit('clear', '');

    }
  },
  mounted() {
    this.current = this.selectedAlpha
  }
}
</script>

<style scoped>

</style>
