<template>
    <footer class="the-footer no-print flex-wrap justify-between w-full"
            style="width: 100%; height: 5.2rem;"
            :class="classes">

      <slot name="data"></slot>
      <div class="flex flex-col">
        <div class="flex pb-3 justify-end" v-if="currentData && currentData.day">
          <p>
            <span class="font-bold">{{ currentData.day }} </span>
            <span class="font-bold" v-if="currentData.start_at">{{ currentData.start_at }} - {{ currentData.end_at }}</span>
            <span class="font-bold" v-else>-</span>
            <span v-if="currentData.l_start_at">
              <span class="font-bold">,{{ $t('Lunch') }} {{ currentData.l_start_at }} - {{ currentData.l_end_at }}</span>
            </span>
          </p>
        </div>
        <p>
          <span>COPYRIGHT &copy; </span>
          <span>{{ new Date().getFullYear() }} <slot name="orgname"></slot></span>
          <a target="_blank" rel="nofollow"></a>
        </p>
      </div>

    </footer>
</template>

<script>
export default {
  data() {
    return {
      today: '',
      currentData: {},
      shopTimings: [],
      days: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
      ],
    };
  },
  name: 'the-footer',
  props: ['classes'],

  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
  },
  watch: {
    '$store.state.AppActiveUser': function() {
      this.updateTimings()
    }
  },
  created() {

  },
  methods: {
    updateTimings() {
      try {
        this.today = this.days[(new Date()).getDay() - 1]
        if(this.activeUserInfo.account_role == 0) {
          try {
            this.shopTimings = JSON.parse(this.activeUserInfo.shop_open_timings)
          } catch (e) {
            this.shopTimings = this.activeUserInfo.shop_open_timings
          }
        } else {
          try {
            this.shopTimings = JSON.parse(this.activeUserInfo.parent_shop_open_timings)
          } catch (e) {
            this.shopTimings = this.activeUserInfo.parent_shop_open_timings
          }
        }

        for(let i = 0; i < this.shopTimings.length; i++) {

          if (this.days[i] == this.today) {
            this.currentData = this.shopTimings[i];
            break
          }
        }
      } catch (e) {

      }
    }
  },
  mounted() {
    // shop_open_timings:"[{"day": "Monday", "end_at": null, "start_at": null, "weekend": false}, {"day": "Tuesday", "end_at": null, "start_at": null, "weekend": false}, {"day": "Wednesday", "end_at": null, "start_at": null, "weekend": false}, {"day": "Thursday", "end_at": null, "start_at": null, "weekend": false}, {"day": "Friday", "end_at": null, "start_at": null, "weekend": false}, {"day": "Saturday", "end_at": null, "start_at": null, "weekend": true}, {"day": "Sunday", "end_at": null, "start_at": null, "weekend": true}]"
    this.updateTimings()
  }
}
</script>
