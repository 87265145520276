<template>
  <div class="flex flex-row h-screen overflow-hidden bg-gray-100 " id="template5">
    <nav class="flex flex-col bg-template5-lightDark w-32 px-1 border-0 border-gray-200 menu-bar"
         style="overflow-y: scroll;">
      <div class="flex flex-wrap mt-8 justify-center">
        <div class="w-full mx-auto">
          <router-link tag="div" class="vx-logo cursor-pointer items-center text-center" style="margin: auto;"
                       to="/dashboard">
            <!--            <logo class="w-10 mr-4 fill-current text-primary" />-->
            <img :src="getlogo()" class="fill-current text-template5-primary mx-auto nav-image" alt="Logo Image"/>
            <span class="vx-logo-text text-white menu-title-hover pt-2 mx-auto fit-text" v-if="getOrganisation()">
             {{ getOrganisation() }}
            </span>
          </router-link>
        </div>
      </div>
      <div class="mt-10 mb-4">
        <ul class="mx-2" :key="getNavMenuItems">
          <template
            v-for="(item, index) in getNavMenuItems">
            <router-link
              v-if="!item.submenu"
              :to="item.url">
              <li
                class="mb-3 px-4 py-4 grid text-black rounded rounded-lg text-center justify-center cursor-pointer"
                :class="[
                  {'bg-template5-warning text-template5-primary hover:text-white': activeLink(item)},
                  {'bg-gray-100 text-template5-primary hover:text-template5-primary': !activeLink(item)},
                ]">
                <span class="mx-auto pb-2" v-if="item.svg">
                  <inline-svg :src="require(`@/assets/svgs/template3/navbar/${item.svg}`)"/>
                </span>
                <p class="font-bold menu-title-hover">
                  <span>{{ $t(item.i18n) || item.name }}</span>
                </p>
              </li>
            </router-link>

            <sub-menu-item v-if="item.submenu" :item="item">
            </sub-menu-item>

          </template>
        </ul>
      </div>
    </nav>

    <div class="py-2 w-full">
      <div class="flex justify-end">

        <div class="px-4 mx-4 my-4 px-2 py-1 flex flex-row ">
          <template v-if="hasCreditAndBalances">
              <div
                class="mx-2 h-14 px-4 bg-template5-warning rounded-lg shadow whitespace-no-wrap text-template4-primary text-start">
                <small class="font-bold" style="font-size: 12px;">{{ $t('Credit') }}</small>
                <p><strong>{{ activeUserInfo.credit | germanNumberFormat }}</strong></p>
              </div>
              <div class="mx-2 px-4 rounded-lg shadow whitespace-no-wrap text-white text-start"
                   :class="[activeUserInfo.balance[activeUserInfo.balance_type] < 0 ? 'bg-template5-danger' : 'bg-template5-primary']">
                <small class="font-bold" style="font-size: 12px;">{{ $t('Balance') }}</small>
                <p class="nav-user-amount">
                  <strong>{{ activeUserInfo.balance[activeUserInfo.balance_type] | germanNumberFormat }}</strong></p>
              </div>
            <div
              class="mx-2 px-4 bg-template5-warning rounded-lg shadow whitespace-no-wrap text-template4-primary text-start"
              v-if="activeUserInfo.account_role >= 2">
              <small class="font-bold" style="font-size: 12px;">{{ $t('Sales') }}</small>
              <p class="nav-user-amount"><strong>{{ activeUserInfo.today_balance | germanNumberFormat }}</strong></p>
            </div>
            <router-link
              class="h-14 mx-2 px-4 rounded-lg shadow whitespace-no-wrap text-white text-start bg-template5-primary"
              to="/seller/sales">
              <div
                class="text-white opacity-100"
                v-if="activeUserInfo.account_role >= 2">
                <small class="font-bold" style="font-size: 12px;">{{ $t('SalesCount') }}</small>
                <p class="nav-user-amount"><strong>{{ activeUserInfo.today_sale_count }}</strong></p>
              </div>
            </router-link>
          </template>
          <div class="h-14 flex flex-row mx-2 px-4 bg-white rounded-lg shadow whitespace-no-wrap text-start">
            <i18n/>
            <div class="bg-gray-200 my-2 mx-3" style="width: 1px;"></div>
            <profile-drop-down/>
          </div>
        </div>
      </div>

      <div class="flex flex-col justify-between content-wrapper">
        <div class="content-area__content content-area h-full overflow-y-auto px-2">

          <transition :name="routerTransition" mode="out-in">
            <router-view
              @changeRouteTitle="changeRouteTitle"
              style="padding-bottom: 100px"
              @setAppClasses="(classesStr) => $emit('setAppClasses', classesStr)"/>
          </transition>
        </div>

        <the-footer :home-data="homeData" style="position: static !important;">
          <template slot="data">
            <div class="flex flex-col">

              <div v-if="activeUserInfo != null" class="flex">

                <div class="flex items-end leading-none mr-1 pb-2" v-if="activeUserInfo.parent_organisation_name">
                  <p style=" font-weight: normal;">{{ activeUserInfo.parent_organisation_name }} - </p>
                </div>
                <div class="flex items-end leading-none mr-4 pb-2" v-if="activeUserInfo.bank_name">
                  <p style=" font-weight: normal;"><strong>Bank </strong> {{ activeUserInfo.bank_name }}</p>
                </div>
                <div class="flex items-end leading-none mr-4 pb-2" v-if="activeUserInfo.iban">
                  <p style=" font-weight: normal;"><strong>IBAN </strong> {{ activeUserInfo.iban }}</p>
                </div>
                <div class="flex items-end leading-none mr-4 pb-2" v-if="activeUserInfo.bic">
                  <p style=" font-weight: normal;"><strong>BIC </strong> {{ activeUserInfo.bic }}</p>
                </div>
              </div>
              <div v-if="homeData != null && homeData.contact_details" class="flex mt-2">
                <div class="flex items-end leading-none mr-4">
                  <p style=" font-weight: bold;">Support</p>
                </div>
                <div class="flex items-end leading-none mr-4">
                  <feather-icon icon="PhoneCallIcon" svgClasses="h-5 w-5" class="mr-4"/>
                  <a style="" :href="'tel:' + homeData.contact_details.phone"><span
                    class="mt-1">{{ homeData.contact_details.phone }}</span></a>
                </div>
                <div class="flex items-end leading-none" v-if="homeData.contact_details.whatsapp">
                  <font-awesome-icon :icon="['fab', 'whatsapp']" size="lg" class="mr-4"/>
                  <!--              <feather-icon icon="PhoneCallIcon" svgClasses="h-5 w-5" class="mr-4" />-->
                  <a style="" :href="'https://wa.me/' + homeData.contact_details.whatsapp.replace(' ', '')"
                     target="_blank">
                    <span class="mt-1">{{ homeData.contact_details.whatsapp }}</span>
                  </a>
                </div>
              </div>
            </div>
          </template>
          <template v-if="homeData != null" slot="orgname">
            <span>{{ homeData.organisation_name }}</span>
          </template>
        </the-footer>
      </div>
    </div>
  </div>
</template>

<script>
import sellerNavMenuItems from "@/layouts/components/vertical-nav-menu/sellerNavMenuItems";
import InlineSvg from 'vue-inline-svg';
import themeConfig from "../../../themeConfig";
import ProfileDropDown from '../components/navbar/components/ProfileDropDown.vue'
import I18n from '../components/navbar/components/I18n.vue'
import AZPagination from "@/components/template3/AZPagination";
import SubMenuItem from "@/layouts/template5/components/SubMenuItem";
import TheFooter from '@/layouts/components/TheFooter.vue'
import * as adminNav from "@/layouts/components/vertical-nav-menu/navMenuItems";
import * as resellerNav from "@/layouts/components/vertical-nav-menu/resellerNavMenuItems";
import * as sellerNav from "@/layouts/components/vertical-nav-menu/sellerNavMenuItems";

export default {
  name: "Main.vue",
  data() {
    return {
      navItems: [],
      componentKey: 0,
      homeData: null,
      routeTitle: '',
      routerTransition: themeConfig.routerTransition || 'none',
    }
  },
  components: {
    SubMenuItem,
    AZPagination,
    InlineSvg,
    ProfileDropDown,
    I18n,
    TheFooter,
  },
  watch: {
    '$route': function () {
      this.routeTitle = this.$route.meta.pageTitle
    },
  },
  computed: {
    // '$store.state.AppActiveUser': function () {
    //   this.updateUser()
    // },
    activeUserInfo() {
      // this.updateUser()
      return this.$store.state.AppActiveUser
    },

    getNavMenuItems() {
      const role = this.activeUserInfo.account_role
      switch (role) {
        case 0:
          adminNav.reload()
          return adminNav.navMenuItems
        case 1:
          resellerNav.reload()
          return resellerNav.navMenuItems
        case 2:
          sellerNav.reload()
          return sellerNav.navMenuItems
        case 3:
          adminNav.reload()
          return adminNav.navMenuItems
        default:
          return []
      }
    },
    hasCreditAndBalances() {
      const role = this.activeUserInfo.account_role
      switch (role) {
        case 1:
          return true
        case 2:
          return true
        case 3:
          return true
        default:
          return false
      }
    },
  },
  methods: {
    updateUser() {
      this.navItems = this.getNavMenuItems;
      this.componentKey += 1;
    },
    changeRouteTitle(title) {
      this.routeTitle = title
    },
    activeLink(item) {
      return !!((item.url === this.$route.path || this.$route.meta.parent === item.slug) && item.url)
    },
    getlogo() {

      let j = JSON.parse(localStorage.getItem('home'))
      if(j.logo) {
        return 'https://pjtelesoftgmbh.de' + j.logo
      } else {

        return 'https://pjtelesoftgmbh.de' + j.login_page_logo
      }
    },
    getOrganisation() {
      return JSON.parse(localStorage.getItem('home')).organisation_name
    },
    fit() {
      setTimeout(() => {
        let e = document.getElementsByClassName('fit-text')
        fitText(e);
      }, 1000)
    },
    getHomeData() {
      try {
        this.homeData = JSON.parse(localStorage.getItem('home'))
      } catch (e) {
        return null
      }
    },
  },
  mounted() {
    this.getHomeData();
    this.fit()
    setTimeout(this.fit, 4000)
    this.updateUser()
  }
}
</script>

<style lang="scss">
.content-wrapper {
  min-height: calc(1vh * 100 - 6.6rem);
  height: calc(1vh * 100 - 6.6rem);
}

.the-footer {
  background: white;
}

body {
  overflow: hidden;
  background-color: #f2f2f2;
}

.menu-title-hover {
  display: none;
}

.menu-bar {
  transition: width 400ms;
}

.nav-image {
  height: auto;
}

.menu-bar:hover {
  .menu-title-hover {
    display: block;
  }

  .nav-image {
    @apply h-24;
  }

  @apply w-80 min-w-80;
}

#template5 {
  #bottom-modal {
    .bottom-modal-card {
      background-color: #2f1c6d;
      color: #e0e1dd;
    }

    .item-card-text {
      color: #000000 !important;
    }

    .flix-input-holder {
      input {
        color: #464646 !important;
      }
    }
  }

  .success-button {
    background-color: #2f1c6d !important;
  }

  .custom-card-value {
    color: #2f1c6d !important;
  }
}
</style>
